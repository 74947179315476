enum Names {
  AddNew = "add-new",
  AdditionalApplications = "additional-applications",
  Advanced = "advanced",
  AdvancedSquare = "advanced-square",
  Algorithms = "algorithms",
  AlgorithmsSquare = "algorithms-square",
  AnalysisGuides = "analysis-guides",
  AnalysisGuidesComputer = "analysis-guides-computer",
  AnalysisGuidesSquare = "analysis-guides-square",
  AnalysisSquare = "analysis-square",
  Apple = "apple",
  ArrowDiag = "arrow-diag",
  ArrowRight = "arrow-right",
  ArrowUp = "arrow-up",
  Bang = "bang",
  BarChart = "bar-chart",
  Billing = "billing",
  BillingSquare = "billing-square",
  Blink = "blink",
  BlueCheckmark = "blue-checkmark",
  Bluetooth = "bluetooth",
  BookOpen = "book-open",
  Checkmark = "checkmark",
  CheckmarkAlt = "checkmark-alt",
  Clipboard = "clipboard",
  Clock = "clock",
  Close = "close",
  Collapse = "collapse",
  CommandLine = "command-line",
  Commands = "commands",
  CommandsSquare = "commands-square",
  Comment = "comment",
  ComputerDownload = "computer-download",
  ComputerDownloadSquare = "computer-download-square",
  Console = "console",
  Context = "context",
  Copy = "copy",
  CopyLink = "copy-link",
  CytAssist = "cytassist",
  Dash = "dash",
  DatasetHumanSpecies = "dataset-human-species",
  DatasetMouseSpecies = "dataset-mouse-species",
  Datasets = "datasets",
  DatasetsSquare = "datasets-square",
  Delete = "delete",
  Documentation = "documentation",
  Download = "download",
  Dropdown = "dropdown",
  Edit = "edit",
  Eject = "eject",
  Email = "email",
  Exit = "exit",
  ExternalLink = "external-link",
  Facebook = "facebook",
  Faqs = "faqs",
  File = "file",
  FileAlt = "file-alt",
  FileAltLight = "file-alt-light",
  Filter = "filter",
  FilterCompact = "filter-compact",
  Funnel = "funnel",
  Github = "github",
  Glossary = "glossary",
  GlossarySquare = "glossary-square",
  GraduationCap = "graduation-cap",
  Hamburger = "hamburger",
  Headset = "headset",
  Home = "home",
  ImageSolid = "image-solid",
  Info = "info",
  InfoSolid = "info-solid",
  Information = "information",
  Instructions = "instructions",
  Language = "language",
  Layers = "layers",
  Lightbulb = "lightbulb",
  LightbulbSmall = "lightbulb-small",
  Linkedin = "linkedin",
  Logo = "logo",
  LoupeBrowser = "loupe-browser",
  LoupeBrowserSquare = "loupe-browser-square",
  LoupeIcon = "loupe-icon",
  Megaphone = "megaphone",
  NavDown = "nav-down",
  NavLeft = "nav-left",
  NavRight = "nav-right",
  NavUp = "nav-up",
  Newspaper = "newspaper",
  Overview = "overview",
  Pause = "pause",
  Pin = "pin",
  Play = "play",
  PlayCircle = "play-circle",
  Plus = "plus",
  Publications = "publications",
  Question = "question",
  QuestionAlt = "question-alt",
  QuestionBubble = "question-bubble",
  QuestionBubbleSquare = "question-bubble-square",
  ReleaseNotes = "release-notes",
  ReleaseNotesSquare = "release-notes-square",
  Remove = "remove",
  Rocket = "rocket",
  Search = "search",
  Security = "security",
  Settings = "settings",
  Sort = "sort",
  Star = "star",
  StarAlt = "star-alt",
  SupportedProductSquare = "supported-product-square",
  Troubleshooting = "troubleshooting",
  Tutorials = "tutorials",
  Twitter = "twitter",
  Usb = "usb",
  User = "user",
  UserAdd = "user-add",
  UserGroup = "user-group",
  Video = "video",
  Videos = "videos",
  Wallet = "wallet",
  Warning = "warning",
  Wifi = "wifi",
  Windows = "windows",
  Wrong = "wrong",
  XeniumAnalyzer = "xenium-analyzer",
  XeniumAnalyzerInstrument = "xenium-analyzer-instrument",
  XeniumExplorer = "xenium-explorer",
  XeniumOnboardAnalysisBody = "xenium_onboard_analysis_body",
  XeniumOnboardAnalysisNav = "xenium_onboard_analysis_nav",
  XeniumRanger = "xenium-ranger",
  XeniumRangerAnalysisBody = "ranger_analysis_body",
  XeniumRangerAnalysisNav = "ranger_analysis_nav",
  Youtube = "youtube",
}

export default Names;
